import instance from "../../../config/axios";
// import {
//   ParseError,
//   getAuthHeader,
//   getWorkspaceId,
// } from "../../../config/utils";

// const GetAgentsReport = async ({ page, limit, type, searchQuery }) => {
//   const pageNumber = page + 1;
//   try {
//     const workspaceId = await getWorkspaceId();
//     const token = await getAuthHeader();
//     const response = await instance.get("/orders/agentsReport", {
//       ...token,
//       params: {
//         workspaceId,
//         page: pageNumber,
//         limit,
//         timeFilter,
//         searchQuery,
//       },
//     });
//     if (response.data.code !== 200) {
//       throw response;
//     } else {
//       return response.data?.data;
//     }
//   } catch (err) {
//     throw ParseError(err?.response?.data || err?.data || err);
//   }
// };

// export { GetAgentsReport };

import {
  ParseError,
  getAuthHeader,
  getWorkspaceId,
} from "../../../config/utils";
const FetchAgentsReport = async (
  page = 1,
  limit = 100000,
  type = "7",
  startDate = "",
  endDate = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/reports/agent?workspaceId=${workspaceId}&page=${page}&limit=${limit}&type=${type}&startDate=${startDate}&endDate=${endDate}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            // ...responseData,
            status: 200,
            data: response.data,
            count: response.data.TotalMembers,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchConversationStatsByFilter = async (startDate = "", endDate = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/agentReports/getConversationStats?workspaceId=${workspaceId}&startTime=${startDate}&endTime=${endDate}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            // ...responseData,
            status: 200,
            data: response.data,
            count: response.data.TotalMembers,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

const FetchAgentStatsByFilter = async (startDate = "", endDate = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/agentReports/conversationAgentReports?workspaceId=${workspaceId}`,
      {
        startTime: startDate,
        endTime: endDate,
      },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            // ...responseData,
            status: 200,
            data: response.data,
            count: response.data.TotalMembers,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchChannelStatsByFilter = async (startDate = "", endDate = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/agentReports/conversationAccountReports?workspaceId=${workspaceId}`,
      {
        startTime: startDate,
        endTime: endDate,
      },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            // ...responseData,
            status: 200,
            data: response.data,
            count: response.data.TotalMembers,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchCustomerDataByFilter = async (startDate = "", endDate = "") => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/agentReports/customerReports?workspaceId=${workspaceId}`,
      {
        startTime: startDate,
        endTime: endDate,
      },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            // ...responseData,
            status: 200,
            data: response.data,
            count: response.data.TotalMembers,
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const FetchAgentsShifts = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/shiftAndKpiSettings/getShiftAndKpiSettings?workspaceId=${workspaceId}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            message: "Shifts Found Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const CreateAgentsShifts = async (shiftDetails, shiftId) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/shiftAndKpiSettings/createShift?workspaceId=${workspaceId}`,
      { shiftDetails, shiftId, workspaceId },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            message: "Shifts Created Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const DeleteAgentsShifts = async (selectedShift) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .delete(
      `/shiftAndKpiSettings/deleteShift/${selectedShift}?workspaceId=${workspaceId}&`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            message: "Shift Deleted Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const ConfigureKpis = async (selectedKPIs) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .put(
      `/shiftAndKpiSettings/`,
      { kpiConfiguration: selectedKPIs, workspaceId },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            message: "Shifts Created Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const GetShiftStatus = async (day) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .get(
      `/shiftAndKpiSettings/getTodayShift?workspaceId=${workspaceId}&day=${day}`,
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            shiftStarted: response?.shiftStarted,
            shiftEnd: response?.shiftEnded,
            breaks: response?.breaks,
            message: "Shifts Status Fetched Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const ManageShiftStatus = async (day, isLoggedIn) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/shiftAndKpiSettings/manageShift`,
      {
        workspaceId,
        day,
        isLoggedIn,
      },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            shiftStarted: isLoggedIn ? true : false,
            shiftEnd: !isLoggedIn ? true : false,
            message: "Shifts Managed Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
const ManageShiftBreaks = async (breakObj) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const workspaceId = await getWorkspaceId();
  const token = await getAuthHeader();
  return instance
    .post(
      `/shiftAndKpiSettings/manageBreaks`,
      { ...breakObj, workspaceId },
      token
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        response = response.data;

        if (response.code === 200) {
          return {
            status: 200,
            data: response.data,
            message: "Break Added Successfully",
          };
        } else {
          return {
            ...responseData,
            message: response.message,
          };
        }
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export {
  FetchAgentsReport,
  FetchConversationStatsByFilter,
  FetchAgentStatsByFilter,
  FetchChannelStatsByFilter,
  FetchCustomerDataByFilter,
  FetchAgentsShifts,
  CreateAgentsShifts,
  ConfigureKpis,
  GetShiftStatus,
  ManageShiftStatus,
  ManageShiftBreaks,
  DeleteAgentsShifts,
};
