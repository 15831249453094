import * as Type from "./types";

const crmWorkspaceSettingReducer = (
  state = { workspaceSettingActingTab: "" },
  action
) => {
  switch (action.type) {
    case Type.SET_WORKSPACE_SETTING_ACTIVE_TAB:
      state = { workspaceSettingActingTab: action.payload };
      break;
    default:
      break;
  }
  return state;
};
export default crmWorkspaceSettingReducer;
