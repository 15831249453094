import * as Types from "./types";

export const SetConversationArr = (data) => ({
  type: Types.SET_CONVERSATION_ARR,
  payload: data,
});

export const SetClosedConversationArr = (data) => ({
  type: Types.SET_CLOSED_CONVERSATION_ARR,
  payload: data,
});

export const SetLoading = (data) => ({
  type: Types.SET_LOADING,
  payload: data,
});

export const SetConversations = (data) => ({
  type: Types.SET_CONVERSATIONS,
  payload: data,
});

export const SetPage = (data) => ({
  type: Types.SET_PAGE,
  payload: data,
});
export const SetClosedConversation = (data) => ({
  type: Types.SET_CLOSED_CONVERSATION,
  payload: data,
});
