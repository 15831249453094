import * as Types from "./types";

export const SetSpam = (data) => ({
  type: Types.SET_SPAM,
  payload: data,
});

export const RemoveSpam = (data) => ({
  type: Types.REMOVE_SPAM,
  payload: data,
});
