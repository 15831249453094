import instance from "../../../config/axios";
import {
  ParseError,
  getAuthHeader,
  getWorkspaceId,
} from "../../../config/utils";

const responseData = {
  loading: false,
  status: 210,
  message: "something.went.wrong",
};

export const getConversationsByWorkspaceId = async (page = 1, limit = 20) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(
      `/conversation?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getConversationsAttachmentsByWorksapceId = async (
  conversationId
) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(
      `/conversation/get-conversation-attachments?workspaceId=${workspaceId}&conversationId=${conversationId}`,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const getSmtpEmails = async () => {
  const token = await getAuthHeader();

  return instance
    .get(`/conversation/fetchSmtpEmails`, token)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getConversationsByFilters = async (data, page = 1, limit = 20) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(
      `/conversation/filter?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      data,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          totalCount: response.totalCount || 0,
          currentPage: response.currentPage || page,
          totalPages: response.totalPages || 0,
          hasMore: response.hasMore || false,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const markAsSpam = async (data) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(
      `/conversation/markConversation-as-spam`,
      { ...data, workspaceId },
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          // spamId: response.spamId,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getConversationsByPagination = async (
  page = 1,
  limit = 20,
  body
) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  const responseData = {
    data: [],
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    hasMore: false,
    status: 400,
    message: "Something went wrong",
  };

  return instance
    .post(
      `/conversation/get-conversations?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      body,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        const responseData = response.data;
        return {
          data: responseData.data || [],
          totalCount: responseData.totalCount || 0,
          currentPage: responseData.currentPage || page,
          totalPages: responseData.totalPages || 0,
          hasMore: responseData.hasMore || false,
          status: 200,
          message: responseData.message || "Success",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.error(err, "Error in getConversationsByPagination");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getFilteredConversationsByPagination = async (
  page = 1,
  limit = 20,
  body
) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();

  const responseData = {
    data: [],
    totalCount: 0,
    currentPage: 0,
    totalPages: 0,
    hasMore: false,
    status: 400,
    message: "Something went wrong",
  };

  return instance
    .post(
      `/conversation/filter?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      body,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        const responseData = response.data;
        return {
          data: responseData.data || [],
          totalCount: responseData.totalCount || 0,
          currentPage: responseData.currentPage || page,
          totalPages: responseData.totalPages || 0,
          hasMore: responseData.hasMore || false,
          status: 200,
          message: responseData.message || "Success",
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.error(err, "Error in getConversationsByPagination");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

// export const getConversationsByPagination = async (
//   page = 1,
//   limit = 20,
//   body
// ) => {
//   const token = await getAuthHeader();
//   const workspaceId = await getWorkspaceId();
//   return instance
//     .post(
//       `/conversation/get-conversations?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
//       body,
//       token
//     )
//     .then((response) => {
//       if (response.data && response.data.code === 200) {
//         response = response.data;
//         return {
//           ...responseData,
//           data: response.data || [],
//           status: 200,
//           message: response.message,
//         };
//       } else {
//         return {
//           ...responseData,
//           message: ParseError(response.data),
//         };
//       }
//     })
//     .catch((err) => {
//       console.log(err, "err");
//       return {
//         ...responseData,
//         message: ParseError(
//           err.response && err.response.data ? err.response.data : err.message
//         ),
//       };
//     });
// };

export const getOrdersByConversationId = async (id, page = 1, limit = 20) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(
      `/conversation/orders/${id}?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;

        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const getTicketsByConversationId = async (id, page = 1, limit = 20) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .get(
      `/conversation/tickets/${id}?workspaceId=${workspaceId}&page=${page}&limit=${limit}`,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const createDraftMessage = async (conversationId, payload) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .post(
      `/conversation/draft/${conversationId}?workspaceId=${workspaceId}`,
      payload,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const editDraftMessage = async (conversationId, payload, draftId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  return instance
    .put(
      `/conversation/draft/${conversationId}?workspaceId=${workspaceId}&draftId=${draftId}`,
      payload,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data || [],
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      // console.log(err, "err");
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const syncConversationMessages = async (pageId, userId) => {
  const token = await getAuthHeader();
  const workspaceId = await getWorkspaceId();
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  return instance
    .get(
      `/conversation/sync-page-conversation?workspaceId=${workspaceId}&pageId=${pageId}&senderId=${userId}`,
      token
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: response.data,
          status: 200,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
