import * as Type from "./types";

const initialState = {
  workspace: null,
  userPermissions: [],
  integrations: [],
  members: [],
  descriptionTemplates: [],
  userRole: null,
  labels: [],
};

const UserWorkspaceSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        workspace: action.payload?.workspace || null,
        userPermissions: action.payload?.userPermissions || [],
        integrations: action.payload?.integrations || [],
        members: action.payload?.members || [],
        descriptionTemplates: action.payload?.descriptionTemplates || [],
        userRole: action?.payload?.userRole || null,
        labels: action?.payload?.labels || [],
      };
    case Type.ADD_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.data,
        ],
      };

    case Type.UPDATE_USER_WORKSPACE_SINGLE_SETTINGS:
      return {
        ...state,
        [action.payload.key]: [...action.payload.data],
      };
    case Type.UPDATE_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].map((item) =>
          item?._id === action.payload.data?._id
            ? { ...item, ...action.payload.data }
            : item
        ),
      };

    case Type.REMOVE_FROM_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        [action.payload.key]: state[action.payload.key]?.filter(
          (item) => (item?._id || item?.id) !== action.payload.id
        ),
      };

    default:
      return state;
  }
};

export default UserWorkspaceSettingsReducer;
