import * as Type from "./types";
const ShiftReducer = (
  state = {
    shiftData: {},
  },
  action
) => {
  switch (action.type) {
    case Type.SET_SHIFT_STATUS:
      return (state = {
        ...state,
        shiftData: action.payload,
      });

    default:
      return state;
  }
};

export default ShiftReducer;
