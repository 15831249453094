import * as Types from "./types";

export const SetUserWorkspaceSettings = (data) => ({
  type: Types.SET_USER_WORKSPACE_SETTINGS,
  payload: data,
});

export const AddUserWorkspaceSettings = (key, data) => ({
  type: Types.ADD_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    data,
  },
});

export const UpdateUserWorkspaceSettings = (key, data) => ({
  type: Types.UPDATE_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    data,
  },
});
export const UpdateUserWorkspaceSingleSettings = (key, data) => ({
  type: Types.UPDATE_USER_WORKSPACE_SINGLE_SETTINGS,
  payload: {
    key,
    data,
  },
});

export const RemoveFromUserWorkspaceSettings = (key, id) => ({
  type: Types.REMOVE_FROM_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    id,
  },
});
