import * as Type from "./types";

const initialState = {
  spam: [],
};

const ConversationSpamReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_SPAM:
      return {
        ...state,
        spam: Array.isArray(action.payload)
          ? [...state.spam, ...action.payload]
          : [...state.spam, action.payload],
      };
    case Type.REMOVE_SPAM:
      return {
        ...state,
        spam: state.spam.filter((id) => id !== action.payload),
      };
    default:
      return state;
  }
};

export default ConversationSpamReducer;
